<template>
    <div class="main">
        <Header :isLight="false" button="btn_color" />
        <div class="error child_wrap ta_center">
            <div class="header error_msg">
                <h1 class="b">Page not found</h1>
                <p>The page you’re looking for does not exist (ㆆ_ㆆ)</p>
                <div class="btn btn_color">
                    <router-link to="/">
                        <button>Back to homepage</button>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="container_black">
            <Footer />
        </div>
    </div>
</template>

<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
export default {
    name: "404",
    components: {
        Header,
        Footer,
    },
};
</script>

<style lang="scss">
.error {
    padding: 200px 0px;
    background-image: url("../assets/404.svg");
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: center;
    height: 80vh;
    h1 {
        font-family: "Monument";
        color: #1f1f1f;
    }
    p {
        color: #454545;
        margin: 10px 0px;
    }
    .btn {
        margin-top: 30px;
        button {
            padding: 20px 40px;
        }
    }
}
</style>
